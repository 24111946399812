import { Link } from "@remix-run/react";
import type { JSX } from "react";
import type { Collector } from "~/types";
import { cn } from "~/util/css";

interface StackedUserAvatarsProps extends Omit<Collector, "id"> {
  index?: number;
  isHorizontal?: boolean;
}

function StackedUserAvatars({
  index,
  isHorizontal,
  avatar_url,
  username,
}: StackedUserAvatarsProps): JSX.Element {
  return (
    <Link
      to={`/${username}`}
      key={username}
      className={cn({
        "ml-[-10px]": (index as number) > 0 && !isHorizontal,
        "mt-[-10px]": (index as number) > 0 && isHorizontal,
      })}
      style={{ zIndex: index }}
    >
      <img
        src={avatar_url || "/default-avatar.svg"}
        alt={username}
        className="w-[38px] h-[38px] rounded-full overflow-hidden object-cover border border-[#FFA9DE] transition-transform duration-300 ease-in-out hover:scale-110"
      />
    </Link>
  );
}

export default StackedUserAvatars;
